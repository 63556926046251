<template>
  <MainLayout :title="title" v-on:logout="$emit('logout')">
    <p
      v-if="
        $mystore.state.event !== null &&
        $mystore.state.event.warmup_message !== ''
      "
      style="white-space: break-spaces"
    >
      {{ $mystore.state.event.warmup_message }}
    </p>
    <p v-else>
      <span style="white-space: pre-wrap"
        ><b>{{ $t('views.questions.noActiveQuestions') }}</b></span
      >
    </p>
    <div
      v-if="
        $mystore.state.event !== null &&
        $mystore.state.event.logo !== null &&
        $mystore.state.event.logo.link !== ''
      "
    >
      <img
        style="width: 100%; max-width: 600px"
        :src="$mystore.state.event.logo.link"
      />
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/Main.vue'

export default {
  name: 'Home',

  components: {
    MainLayout,
  },

  data() {
    return {
      title: this.$t('views.home.title'),
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.title = this.$t('views.home.title')
      document.title = this.title
    },
  },

  created: function () {
    document.title = this.title
  },
}
</script>
