var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',{attrs:{"title":_vm.title},on:{"logout":function($event){return _vm.$emit('logout')}}},[(
      _vm.$mystore.state.event !== null &&
      _vm.$mystore.state.event.warmup_message !== ''
    )?_c('p',{staticStyle:{"white-space":"break-spaces"}},[_vm._v(" "+_vm._s(_vm.$mystore.state.event.warmup_message)+" ")]):_c('p',[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_c('b',[_vm._v(_vm._s(_vm.$t('views.questions.noActiveQuestions')))])])]),(
      _vm.$mystore.state.event !== null &&
      _vm.$mystore.state.event.logo !== null &&
      _vm.$mystore.state.event.logo.link !== ''
    )?_c('div',[_c('img',{staticStyle:{"width":"100%","max-width":"600px"},attrs:{"src":_vm.$mystore.state.event.logo.link}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }